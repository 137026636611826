// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// components
import SvgIconStyle from '../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name, folder = 'navbar') => (
  <SvgIconStyle src={`/static/icons/${folder}/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
);

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  booking: getIcon('ic_booking'),
  filePDF: getIcon('file_type_pdf', 'file')
};

const sidebarConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  // {
  //   subheader: 'general',
  //   items: [
  //     {
  //       title: 'app',
  //       path: PATH_DASHBOARD.general.app,
  //       icon: ICONS.dashboard
  //     },
  //     { title: 'papers', path: PATH_DASHBOARD.general.ecommerce, icon: ICONS.ecommerce },
  //     { title: 'analytics', path: PATH_DASHBOARD.general.analytics, icon: ICONS.analytics },
  //     { title: 'banking', path: PATH_DASHBOARD.general.banking, icon: ICONS.banking },
  //     { title: 'booking', path: PATH_DASHBOARD.general.booking, icon: ICONS.booking }
  //   ]
  // },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    // subheader: 'management',
    items: [
      // MANAGEMENT : E-COMMERCE
      {
        title: 'papers',
        path: PATH_DASHBOARD.eCommerce.shop,
        icon: ICONS.filePDF
        // children: [
        // { title: 'shop', path: PATH_DASHBOARD.eCommerce.shop }
        // { title: 'product', path: PATH_DASHBOARD.eCommerce.productById },
        // { title: 'list', path: PATH_DASHBOARD.eCommerce.list },
        // { title: 'edit', path: PATH_DASHBOARD.eCommerce.editById },
        // { title: 'checkout', path: PATH_DASHBOARD.eCommerce.checkout },
        // { title: 'invoice', path: PATH_DASHBOARD.eCommerce.invoice }
        // ]
      },
      {
        title: 'Practice Test',
        path: PATH_DASHBOARD.paperTest.practiceTest,
        icon: ICONS.analytics
        // children: [
        //   { title: 'Test', path: PATH_DASHBOARD.paperTest.practiceTest }
        //   // { title: 'Test History', path: PATH_DASHBOARD.paperTest.practiceTestHistory },
        // ]
      },
      {
        title: 'Referral',
        path: PATH_DASHBOARD.referral.root,
        icon: ICONS.banking
        // children: [
        //   { title: 'Test', path: PATH_DASHBOARD.paperTest.practiceTest }
        //   // { title: 'Test History', path: PATH_DASHBOARD.paperTest.practiceTestHistory },
        // ]
      },
      // MANAGEMENT : USER
      {
        title: 'user',
        path: PATH_DASHBOARD.user?.root,
        icon: ICONS.user,
        children: [
          // { title: 'profile', path: PATH_DASHBOARD.user?.profile },
          // { title: 'cards', path: PATH_DASHBOARD.user?.cards },
          // { title: 'list', path: PATH_DASHBOARD.user?.list },
          // { title: 'create', path: PATH_DASHBOARD.user?.newUser },
          // { title: 'edit', path: PATH_DASHBOARD.user?.editById },
          { title: 'profile', path: PATH_DASHBOARD.user?.account },
          // { title: 'dashboard', path: PATH_DASHBOARD.general.ecommerce, icon: ICONS.ecommerce },
          // { title: 'create', path: PATH_DASHBOARD.eCommerce.newProduct },
          { title: 'Buy History', path: PATH_DASHBOARD.eCommerce.list },
          { title: 'Practice History', path: PATH_DASHBOARD.user.practiceTest }
        ]
      }

      //MANAGEMENT : BLOG
      // {
      //   title: 'admin',
      //   path: PATH_DASHBOARD.blog.root,
      //   icon: ICONS.blog,
      //   children: [
      //     { title: 'posts', path: PATH_DASHBOARD.blog.posts },
      //     { title: 'post', path: PATH_DASHBOARD.blog.postById },
      //     { title: 'new post', path: PATH_DASHBOARD.blog.newPost }
      //   ]
      // }
    ]
  }

  // APP
  // ----------------------------------------------------------------------
  // {
  //   subheader: 'app',
  //   items: [
  //     {
  //       title: 'mail',
  //       path: PATH_DASHBOARD.mail.root,
  //       icon: ICONS.mail,
  //       info: <Label color="error">2</Label>
  //     },
  //     { title: 'chat', path: PATH_DASHBOARD.chat.root, icon: ICONS.chat },
  //     { title: 'calendar', path: PATH_DASHBOARD.calendar, icon: ICONS.calendar },
  //     {
  //       title: 'kanban',
  //       path: PATH_DASHBOARD.kanban,
  //       icon: ICONS.kanban
  //     }
  //   ]
  // }
];

export default sidebarConfig;

export const adminConfig = {
  title: 'admin',
  path: PATH_DASHBOARD.blog.root,
  icon: ICONS.dashboard,
  children: [
    // { title: 'Users Dashboard', path: PATH_DASHBOARD.admin.usersDashboard },
    { title: 'Paper Dashboard', path: PATH_DASHBOARD.admin.paperDashboard },
    { title: 'Paper Visibility', path: PATH_DASHBOARD.admin.paperVisibility },
    { title: 'Paper Access', path: PATH_DASHBOARD.admin.paperAccess },
    { title: 'Vouchers', path: PATH_DASHBOARD.admin.vouchersDashboard },
    { title: 'Transfer Account', path: PATH_DASHBOARD.admin.transferAccount }
  ]
};
